export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dasbor',
        to: '/dashboard',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Produk',
        route: '/product',
        icon: 'cil-applications',
        items: [
          {
            name: 'Prabayar',
            to: '/product/prepaid'
          },
          // {
          //   name: 'Pascabayar',
          //   to: '/product/postpaid'
          // },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Daftar Transaksi',
        to: '/transaction_list',
        icon: 'cil-list',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Deposit',
        to: '/deposit',
        icon: 'cil-level-up',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Setting',
        to: '/setting',
        icon: 'cil-settings',
      },
    ]
  }
]