<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

import axios from "../apis/api";
import channel from "../apis/pusher";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },

  methods: {
    async getUser() {
      const response = await axios.get("/user");
      this.$store.commit("set", ["user", response.data.data]);
    },
    async getBalance() {
      const response = await axios.get("/balance");
      this.$store.commit("set", ["balance", response.data.data.balance]);
    },
  },
  created() {
    this.getUser();
    this.getBalance();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
