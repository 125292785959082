<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none d-none" to="/">
      <CIcon name="logo" height="48" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="mr-auto">
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink> Settings </CHeaderNavLink>
      </CHeaderNavItem>
      -->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      -->
      <CHeaderNavItem class="mx-2 h4">
        <h3>
          <CBadge :color="balance < 0 ? 'danger' : 'success'">
            <strong>{{ balance | currency }}</strong>
          </CBadge>
          <router-link to="/deposit">
            <CButton color="primary" size="sm" variant="outline" class="mx-1">
              <CIcon name="cil-plus" />
            </CButton>
          </router-link>
        </h3>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader
      class="p-2 m-2"
      v-if="
        !user.pin_set || !user.phone_verification || !user.email_verification
      "
    >
      <CAlert class="mx-1 p-1" color="danger" v-if="!user.pin_set">
        PIN Belum dibuat, silahkan ke menu setting.
      </CAlert>
      <CAlert class="mx-1 p-1" color="danger" v-if="!user.phone_verification">
        Nomor HP Belum diverifikasi, silahkan ke menu setting.
      </CAlert>
      <CAlert class="mx-1 p-1" color="danger" v-if="!user.email_verification">
        Email Belum diverifikasi, silahkan ke menu setting.
      </CAlert>
      <!-- <CBreadcrumbRouter class="border-0 mb-0" /> -->
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },

  computed: {
    balance() {
      let balance = this.$store.state.balance;
      return balance;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {},
};
</script>
