<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://isigo.id" target="_blank">isigo</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="mfs-auto">
      <CIcon name="cil-history" />
      <span class="mr-1">&nbsp;&nbsp;v{{ appVersion }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
  data: () => ({
    appVersion: process.env.VUE_APP_VERSION,
  }),
};
</script>
